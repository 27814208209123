/**
 * Funcion que retorna la expresion regular para la validacion del correo electronico
 */
export const getPatterEmail = () => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
};

//Convertir un numero a formato moneda
export const convertCurrencyNumber = (number) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

//Funcion para convertir blob a File
export function blobToFile(blob, fileName) {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
}

//Funcion que retorna la fecha actual
export const getActualDate = () => {
  let dateActual = new Date();
  let year = dateActual.getFullYear();
  let month = (dateActual.getMonth() + 1).toString().padStart(2, "0");
  var day = dateActual.getDate().toString().padStart(2, "0");
  return year + "-" + month + "-" + day;
};

//Funcion para convertir la primera palabra de un string a Mayuscula
export const convertString = (value) => {
  const splitName = value.split(" ");
  const finalString = splitName.map((word) => {
    return word !== "" ? word[0].toUpperCase() + word.slice(1).toLowerCase() : "";
  });
  return finalString.join(" ");
};

