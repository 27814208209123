import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bugraphs } from "../components/Builders/Bugrafx/Bugraphs";
import { getDataUser, getMenuUser, setTemporalUsers } from "../libs/redux/slices/authSlice/authSlice";
import { useGetGraphsQuery } from "../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getModuleSelected, getProcesSelected } from "../libs/redux/slices/MenuSlice/menuSlice";
import { useGetUserTemporalQuery } from "../libs/redux/slices/authSlice/authApiSlice";

function DashboardHome() {
  const dispatch = useDispatch();
  const { MODURLXX } = useSelector(getModuleSelected);
  const { PRODURLX } = useSelector(getProcesSelected);
  const menuData = useSelector(getMenuUser);
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const { data: graphs } = useGetGraphsQuery({ USRIDXXX, CARIDXXX });
  const { data: temporalUsers, isSuccess } = useGetUserTemporalQuery({ USRIDXXX });

  //Hook para el seteo de los usuarios reemplazo
  useEffect(() => {
    if (isSuccess) {
      const { data = [] } = temporalUsers;
      dispatch(setTemporalUsers(data));
    }
  },[isSuccess, temporalUsers, dispatch]);

  const searchValidMenu = () => {
    let menuItems = {};
    menuData.forEach((menu) => {
      if (menu.MODIDXXX === 107) {
        menu.CIMS0004.forEach((submenu) => {
          if (submenu.PROIDXXX === 101) {
            menuItems = Object.assign({
              moduleItem: menu,
              processItem: submenu,
              isValid: true,
            });
          }
        });
      }
    });
    return menuItems;
  };

  const isValidPrintGraph = () => {
    const { isValid } = searchValidMenu();
    if (MODURLXX === undefined && PRODURLX === undefined && isValid) {
      return true;
    }
    return false;
  };

  if (isValidPrintGraph()) {
    return graphs && <Bugraphs graphs={graphs.data} />;
  }
  return <></>;
}

export default DashboardHome;
