import { createSlice } from "@reduxjs/toolkit";

const customFilters = {
  page: 1,
  perPage: process.env.REACT_APP_ROWS_PER_PAGE_TRACKING,
  sorts: "-REGFECXX,-REGHORXX",
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    data: {},
    columns: [],
    colsTracking: [],
    customFilters: customFilters,
    totalData: 0,
    filters: [],
    showModalConsult: false,
    extraFilters: [],
    hideColumn: {},
    modalShow: "",
    restoreFilts: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setColsTracking: (state, action) => {
      state.colsTracking = action.payload;
    },
    setCustomFilters: (state, action) => {
      state.customFilters = action.payload;
    },
    setSortTracking: (state, action) => {
      state.customFilters = { ...state.customFilters, sorts: action.payload };
    },
    setTotalData: (state, action) => {
      state.totalData = action.payload;
    },
    cleanDataTracking: (state) => {
      state.dataTracking = {};
    },
    setFiltersTracking: (state, action) => {
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = {};
    },
    setExtraFilters: (state, action) => {
      state.extraFilters = action.payload;
    },
    setHideColumn: (state, action) => {
      state.hideColumn = action.payload;
    },
    setModalShow: (state, action) => {
      state.modalShow = action.payload;
    },
    resetCustomFilters: (state) => {
      state.customFilters = customFilters;
    },
    setRestoreFilts: (state, action) => {
      state.restoreFilts = action.payload;
    }
  },
});
export const {
  setData,
  setColumns,
  setColsTracking,
  setCustomFilters,
  setTotalData,
  cleanDataTracking,
  setFiltersTracking,
  resetFilters,
  setExtraFilters,
  setHideColumn,
  setFunctionConsult,
  setModalShow,
  resetCustomFilters,
  setSortTracking,
  setRestoreFilts,
} = trackingSlice.actions;
export default trackingSlice.reducer;

//Export Data
export const getData = (state) => {
  return state.tracking.data;
};
//Export Columns
export const getColumns = (state) => {
  return state.tracking.columns;
};
//Columnas del tracking
export const getColsTracking = (state) => {
  return state.tracking.colsTracking;
};
//Filtros personalizados
export const getCustomFilters = (state) => {
  return state.tracking.customFilters;
};
//Total Data
export const getTotalData = (state) => {
  return state.tracking.totalData;
};
//Filtros del tracking
export const getFiltersTracking = (state) => {
  return state.tracking.filters;
};
export const getExtraFilters = (state) => {
  return state.tracking.extraFilters;
};
export const getHideColumn = (state) => {
  return state.tracking.hideColumn;
};
export const getModalShow = (state) => {
  return state.tracking.modalShow;
};
export const getRestoreFilts = (state) => {
  return state.tracking.restoreFilts;
};
